

























































import { QMenu } from 'quasar'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Component
export default class Menu extends Vue {
  @Prop({ type: Boolean }) private readonly showImportOption?: boolean

  @Ref() menu!: QMenu

  show (): void {
    this.menu.show()
  }

  mounted () {
    /**
     * Due to lazy loading, the first time should be shown automatically 🤷‍♂️
     */
    this.show()
  }
}
